import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import TextInput from "clutch/src/TextInput/TextInput.jsx";
import type { TFunction } from "i18next";
import { t as gt } from "i18next";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import {
  ArmorDuplicateTable,
  ArmorSidebar,
  // PerkTable,
  TraitsDisplay,
} from "@/game-destiny2/components/DatabaseComponents.jsx";
import { ItemIcon } from "@/game-destiny2/components/ItemIcon.jsx";
import {
  ARMOR_SUB_CATEGORIES,
  SUB_CATEGORIES,
} from "@/game-destiny2/constants/category.mjs";
import { CLASS } from "@/game-destiny2/constants/class.mjs";
import { RARITY, RARITY_LIST } from "@/game-destiny2/constants/rarity.mjs";
import { SEASON } from "@/game-destiny2/constants/season.mjs";
import { GAME_SYMBOL_DESTINY2 } from "@/game-destiny2/definition.mjs";
import type { Armor, Mod, Stat } from "@/game-destiny2/models/model-wiki.mjs";
import {
  getWikiData,
  useWikiData,
} from "@/game-destiny2/utils/use-wiki-data.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import { DataTableExtSort, DataTableLoading } from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder.jsx";
import { WikiLayout } from "@/shared/Wiki.jsx";
import { useQuery, useQueryValues, useRoute } from "@/util/router-hooks.mjs";

const ClassIcon = ({ hash, t }: { hash: number; t: TFunction }) => {
  if (!CLASS[hash]) return "-";

  const { icon: Icon, label } = CLASS[hash];
  return (
    <div className="flex align-center gap-1">
      <Icon />
      {t(...label)}
    </div>
  );
};

type Column = (typeof columns)[number]["slug"];
const columns = [
  {
    label: ["common:wiki.name", "Name"],
    slug: "name",
    col: {
      align: "left",
      primary: true,
    },
    row: (a: Armor) => ({
      display: <ItemIcon item={a} title={a.label} />,
      value: a.label,
      link: `/destiny2/database/armor/${a.referenceId}/${a.slug}`,
    }),
  },
  {
    label: ["common:wiki.category", "Category"],
    slug: "category",
    col: {},
    row: (a: Armor, t: TFunction) => ({
      display: t(...SUB_CATEGORIES[a.subCategory].label),
      value: t(...SUB_CATEGORIES[a.subCategory].label),
    }),
  },
  {
    label: ["common:wiki.season", "Season"],
    slug: "season",
    col: {},
    row: (a: Armor) => ({
      display: SEASON[a.seasonId].seasonId.toLocaleString(),
      value: SEASON[a.seasonId].seasonId,
    }),
  },
  {
    label: ["destiny2:reqClass", "Req. Class"],
    slug: "class",
    col: {},
    row: (a: Armor, t: TFunction) => ({
      display: <ClassIcon t={t} hash={a.reqClass} />,
      value: a.reqClass,
    }),
  },
  {
    label: ["common:wiki.rarity", "Rarity"],
    slug: "rarity",
    col: {},
    row: (a: Armor, t: TFunction) => ({
      display: (
        <span style={{ color: RARITY[a.rarity].color }}>
          {t(...RARITY[a.rarity].label)}
        </span>
      ),
      value: RARITY[a.rarity].tier,
    }),
  },
] as const;

type FILTER = keyof typeof FILTERS;
const FILTERS = {
  category: {
    options: [
      {
        text: ["common:wiki.allArmor", "All Armor"],
        value: "ALL",
      },
      ...ARMOR_SUB_CATEGORIES.map((c) => ({
        text: c.label,
        value: c.slug,
        filterValue: c.referenceId,
      })),
    ],
    key: "subCategory",
  },
  class: {
    options: [
      {
        text: ["destiny2:wiki.allClasses", "All Classes"],
        value: "ALL",
      },
      ...Object.values(CLASS).map((c) => ({
        text: c.label,
        value: c.slug,
        filterValue: c.referenceId,
      })),
    ],
    key: "reqClass",
  },
  rarity: {
    options: [
      {
        text: ["common:wiki.allRarities", "All Rarities"],
        value: "ALL",
      },
      ...RARITY_LIST.map((r) => ({
        text: r.label,
        value: r.slug,
        filterValue: r.referenceId,
      })),
    ],
    key: "rarity",
  },
} as const;

type BaseFilters = {
  nameFilter: string;
  setNameFilter(a: string): void;
  sortOptions: { text: Translation; value: Column }[];
  sortBy: Column;
  setSortBy(a: Column): void;
  sortDir: "ASC" | "DESC";
  setSortDir(a: "ASC" | "DESC"): void;
};
type FiltersProps =
  | (BaseFilters & { disabled?: never })
  | (Partial<BaseFilters> & { disabled: true });
// TODO: filters class, type, rarity, season?
function Filters({
  nameFilter,
  setNameFilter,
  sortOptions = [],
  sortBy,
  setSortBy,
  sortDir,
  setSortDir,
  disabled,
}: FiltersProps) {
  const { t } = useTranslation();
  const [catFilter, setCatFilter] = useQuery<string>("category", "ALL");
  const [classFilter, setClassFilter] = useQuery<string>("class", "ALL");
  const [rarityFilter, setRarityFilter] = useQuery<string>("rarity", "ALL");

  return (
    <div className="search-container">
      <TextInput
        disabled={disabled}
        placeholder={t("common:search", "Search")}
        onChange={(e) => setNameFilter(e.target.value)}
        defaultValue={nameFilter}
        Icon={SearchIcon}
      />
      <Select
        disabled={disabled}
        options={FILTERS.category.options}
        selected={catFilter}
        onChange={setCatFilter}
      />
      <Select
        disabled={disabled}
        options={FILTERS.class.options}
        selected={classFilter}
        onChange={setClassFilter}
      />
      <Select
        disabled={disabled}
        options={FILTERS.rarity.options}
        selected={rarityFilter}
        onChange={setRarityFilter}
      />
      <div className="sort-container">
        <Select
          disabled={disabled}
          options={sortOptions}
          selected={sortBy}
          onChange={setSortBy}
        />
        <SortOrder
          disabled={disabled}
          sortOrder={sortDir}
          setSortOrder={setSortDir}
        />
      </div>
    </div>
  );
}

const ArmorLanding = ({
  armor,
  stats,
}: {
  armor: Record<number, Armor>;
  stats: Record<number, Stat>;
}) => {
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [sortBy, setSortBy] = useQuery<Column>("sortBy", "rarity");
  const [sortDir, setSortDir] = useQuery<"ASC" | "DESC">("dir", "DESC");
  const filters = useQueryValues(Object.keys(FILTERS) as FILTER[]);

  const rows = useMemo(
    () =>
      armor &&
      Object.values(armor)
        .filter((a) => {
          for (const f of Object.keys(FILTERS)) {
            const filterSlug = filters[f];
            if (filterSlug) {
              const filterValue = FILTERS[f].options.find(
                (f) => f.value === filterSlug,
              )?.filterValue;
              if (a[FILTERS[f].key] !== filterValue) return false;
            }
          }
          return true;
        })
        .filter((a) => a.isArmor2)
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((a) => columns.map((c) => c.row(a, t))),
    [armor, filters, t],
  );

  if (!armor || !stats)
    return (
      <WikiLayout
        gameSymbol={GAME_SYMBOL_DESTINY2}
        title={["common:wiki.armor", "Armor"]}
      >
        <div className="main">
          <Filters
            nameFilter={nameFilter}
            sortOptions={columns.map((c) => ({ text: c.label, value: c.slug }))}
            sortDir={sortDir}
            disabled
          />
          <DataTableLoading />
        </div>
      </WikiLayout>
    );

  return (
    <WikiLayout
      gameSymbol={GAME_SYMBOL_DESTINY2}
      title={["common:wiki.armor", "Armor"]}
    >
      <div className="main">
        <Filters
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          sortOptions={columns.map((c) => ({ text: c.label, value: c.slug }))}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDir={sortDir}
          setSortDir={setSortDir}
        />
        <DataTableExtSort
          cols={columns.map((c) => ({
            display: t(...c.label),
            ...c.col,
          }))}
          rows={rows}
          searchCol={0}
          searchText={nameFilter}
          sortCol={columns.findIndex((c) => c.slug === sortBy)}
          sortDir={sortDir}
          setSelectedSortCol={(idx) => setSortBy(columns[idx].slug)}
          setSelectedSortDir={setSortDir}
        />
      </div>
    </WikiLayout>
  );
};

function ArmorView({
  armorId,
  armor: armorMap,
  stats,
  plugLists,
  mods,
  lore,
}: {
  armorId: string;
  armor: Record<string, Armor>;
  stats: Record<string, Stat>;
  plugLists: Record<string, number[]>;
  mods: Record<string, Mod>;
  lore: Record<string, string>;
}) {
  const { t } = useTranslation();
  const armor = armorMap?.[armorId];
  // TODO: loading states :(
  if (!armor || !mods || !plugLists || !stats || !lore) return null;

  const traits = armor.traits?.map((hash) => mods[hash]);
  return (
    <WikiLayout
      gameSymbol={GAME_SYMBOL_DESTINY2}
      title={armor.label}
      imgSrc={`${appURLs.BUNGIE}/${armor.iconUri}`}
    >
      <div className="sidebar">
        <ArmorSidebar {...armor} statMap={stats} />
      </div>
      <div className="main">
        {(!!traits?.length || armor.source) && (
          <Card className="flex column gap-4">
            {armor.source && <p>{armor.source}</p>}
            {!!traits?.length && (
              <>
                <h3 className="type-subtitle--bold">
                  {t("destiny2:wiki.traits", "Traits")}
                </h3>
                <TraitsDisplay traits={traits} />
              </>
            )}
          </Card>
        )}
        <ArmorDuplicateTable
          armor={armor.alternates
            ?.map((hash) => armorMap[hash])
            .filter((i) => i.isArmor2)}
        />
        {/* Armor has a lot of strange rules & for perks. Needs a dedicated follow up */}
        {/* {armor.sockets && (
          <PerkTable
            mods={mods}
            plugLists={plugLists}
            sockets={armor.sockets}
            stats={stats}
          />
        )} */}
        {!!armor.lore && (
          <Card className="flex column gap-4">
            <h3 className="type-subtitle--bold">
              {t("destiny2:wiki.lore", "Lore")}
            </h3>
            <p style={{ whiteSpace: "preserve-breaks" }}>{lore[armor.lore]}</p>
          </Card>
        )}
      </div>
    </WikiLayout>
  );
}

function DatabaseArmor() {
  const {
    parameters: [armorId],
  } = useRoute();
  const { armor, stats, mods, plugLists, lore } = useWikiData();

  if (armorId)
    return (
      <ArmorView
        armorId={armorId}
        armor={armor}
        stats={stats}
        plugLists={plugLists}
        mods={mods}
        lore={lore}
      />
    );

  return <ArmorLanding armor={armor} stats={stats} />;
}

export default DatabaseArmor;

export function meta([weaponId]) {
  const { armor: armorMap } = getWikiData();
  const armor = armorMap?.[weaponId];

  const armorName = armor ? armor.label : gt("common:wiki.armor", "Armor");
  if (weaponId && (!armorMap || armor))
    return {
      title: [
        "destiny2:armorSeo.title",
        "{{armorName}} in Destiny 2: Complete Profile and Item Manager | Blitz",
        { armorName },
      ],
      description: ["", armor?.tagLine],
      subtitle: true,
    };

  return {
    title: ["destiny2:armorLanding.title", "Destiny 2 Armor"],
    description: [
      "destiny2:armorLanding.desc",
      "Explore the most comprehensive Destiny 2 Armor Database on Blitz.gg! Find up-to-date stats, tier lists, and guides for all active Destiny 2 armor to enhance your gameplay.",
    ],
    subtitle: !IS_APP,
  };
}
